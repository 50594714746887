import {
  ActivityReportReadyForDownloadWebsocketData,
  GreenCheckWebSocketMessage,
  NewNotificationWebsocketData,
  Notification
} from '@gcv/shared';
import { NotificationsApi } from 'api';
import { environment } from 'environments/environment';
import { injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { downloadFileFromSignedUrl } from 'util/files.util';
import {
  filterNotificationsByBannerOrDrawerType,
  filterNotificationsByOrg,
  renderSnackBarNotification
} from 'util/notifications.util';
import { SnackbarSeverity, getSnackbarStore } from './SnackBarStore';
import { getUserStore } from './UserStore';

@injectable()
export class NotificationStore {
  interval: NodeJS.Timeout | undefined;
  isDownloadingReport = false;
  isPolling = false;
  notifications: Notification[] = [];
  notificationsApi = new NotificationsApi();
  pendingReportsToDisplay = [] as GreenCheckWebSocketMessage[];
  showReportSnackbar = true;
  webSocket?: WebSocket;
  webSocketMessage: GreenCheckWebSocketMessage = {} as GreenCheckWebSocketMessage;
  snackbarStore = getSnackbarStore();
  currentOrgId = '';

  constructor() {
    makeAutoObservable(this);
  }

  storeNotifications = (data: Notification[]) => {
    const { bannerNotifications, drawerNotifications } = filterNotificationsByBannerOrDrawerType(data);
    const notifications = filterNotificationsByOrg(this.currentOrgId, drawerNotifications);

    runInAction(() => {
      for (const d of notifications) {
        const index = this.notifications.findIndex((n) => n.id === d.id);

        if (index === -1) {
          this.notifications.push(d);
        }
      }
    });

    for (const bannerNotification of bannerNotifications) {
      renderSnackBarNotification(bannerNotification);
    }
  };

  getAllUserNotifications = (orgId: string, userId: string) => {
    this.notifications = [];
    this.currentOrgId = orgId;
    this.notificationsApi.getNotifications(orgId, userId).then(this.storeNotifications);
  };

  removeNotification = (notificationId: string) => {
    const index = this.notifications.findIndex((n) => n.id === notificationId);

    if (index !== -1) {
      this.notifications.splice(index, 1);
    }
  };

  /**
   * Start notifications polling.
   *
   * Polling starts only if it isn't already running.
   *
   * @param orgId ID of bank, dispensary, etc.
   * @param userId ID of user
   * @returns true if polling was started; false, if already running.
   */
  startPolling = (orgId: string, userId: string): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      if (!this.isPolling) {
        runInAction(() => {
          this.isPolling = true;
        });

        this.interval = setInterval(() => {
          this.notificationsApi
            .getNotifications(orgId, userId, DateTime.utc().minus({ minutes: 10 }).toISO())
            .then(this.storeNotifications);
        }, 1000 * 60 * 10); // 10 minutes

        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  stopPolling = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    runInAction(() => {
      this.isPolling = false;
    });
  };

  // Websockets
  setupWebSocket = action((force?: boolean) => {
    if (force) {
      this.webSocket = new WebSocket(`${environment.gcvConfig.webSocketUrl}?token=${this.userStore.user.id}`);
    } else {
      // do not reconnect the websocket connection if there is already one in the store
      this.webSocket =
        this.webSocket ??
        new WebSocket(`${environment.gcvConfig.webSocketUrl}?token=${this.userStore.user.id}`);
    }

    this.webSocket.addEventListener('message', (event) => {
      // parse the message and save it to the store for other pages to react to
      const socketMessage = JSON.parse(event.data) as GreenCheckWebSocketMessage;
      this.webSocketMessage = socketMessage;

      if (socketMessage.action === 'activity_report_ready_for_download') {
        if (this.showReportSnackbar) {
          this.displayReportSnackbar(socketMessage);
        } else {
          this.pendingReportsToDisplay.push(socketMessage);
        }
      }

      if (socketMessage.action === 'new_notification') {
        const notificationMessage = this.webSocketMessage.data as NewNotificationWebsocketData;
        this.storeNotifications([notificationMessage.notification]);
      }
    });

    this.listenForIdleDisconnection();
  });

  addWebsocketHandler = action((handler: (event: MessageEvent) => void) => {
    if (this.webSocket) {
      this.webSocket.addEventListener('message', handler);
    }
  });

  listenForIdleDisconnection = action(() => {
    if (this.webSocket && !this.webSocket.onclose) {
      this.webSocket.onclose = (e) => {
        // 1001 (Going away) - https://www.rfc-editor.org/rfc/rfc6455
        if (e.code === 1001) {
          this.setupWebSocket(true);
        }
      };
    }
  });

  displayReportSnackbar = (socketMessage: GreenCheckWebSocketMessage) => {
    const socketData = socketMessage.data as ActivityReportReadyForDownloadWebsocketData;
    this.snackbarStore.showSnackbar(
      SnackbarSeverity.Info,
      `Your report is ready. Click to download ${socketData.friendlyReportName}.`,
      async () => {
        if (!this.isDownloadingReport) {
          try {
            runInAction(() => {
              this.isDownloadingReport = true;
            });
            downloadFileFromSignedUrl(socketData.s3Link);
          } catch (error) {
            getSnackbarStore().showErrorSnackbarMessage('Failed to download the report');
          } finally {
            runInAction(() => {
              this.isDownloadingReport = false;
            });
            getSnackbarStore().hideSnackbar();
          }
        }
      },
      true
    );
  };

  setReportsWebSocketListener = () => {
    if (this.webSocket) {
      this.webSocket.addEventListener('message', (event) => {
        const socketMessage = JSON.parse(event.data) as GreenCheckWebSocketMessage;
        if (socketMessage.action === 'activity_report_ready_for_download') {
          if (this.showReportSnackbar) {
            this.displayReportSnackbar(socketMessage);
          } else {
            this.pendingReportsToDisplay.push(socketMessage);
          }
        }
      });
    }
  };

  setShowReportSnackbar = action((value: boolean) => {
    this.showReportSnackbar = value;
  });

  showPendingSnackbars = action(() => {
    for (let index = 0; index < this.pendingReportsToDisplay.length; index++) {
      const socketMessage = this.pendingReportsToDisplay[index];
      this.displayReportSnackbar(socketMessage);
      this.pendingReportsToDisplay.splice(index, 1);
    }
  });

  clearStore = action(() => {
    this.notifications = [];
    this.webSocket?.close();
    this.webSocket = undefined;
  });

  userStore = getUserStore();
}

let notificationStore: NotificationStore | undefined;

export function getNotificationStore(): NotificationStore {
  if (!notificationStore) {
    notificationStore = new NotificationStore();
  }

  return notificationStore;
}
